import type { Coords } from 'google-map-react';
import type { APCountry } from '@modules/root';
import { GOOGLE_MAP_STYLES } from './googleMapStyles';
import type { SupportedCountry } from '@archipro-website/localisation';

interface ArchiProMapVariables {
    center: Coords;
    zoom: {
        search: number;
        click: number;
        max: number;
        min: number;
    };
    latitudeOffset: number;
    restriction?: {
        latLngBounds: {
            east: number;
            west: number;
            north: number;
            south: number;
        };
        strictBounds: boolean;
    };
}

const NZ_MAP_VARIABLES_DESKTOP: ArchiProMapVariables = {
    center: { lat: -41.2851, lng: 174.776 },
    zoom: {
        search: 5.5,
        click: 9,
        max: 19,
        min: 5.5,
    },
    latitudeOffset: 0.04,
    restriction: {
        latLngBounds: {
            east: 190.00304,
            west: 155.696896,
            north: -24.75318,
            south: -53.021069,
        },
        strictBounds: false,
    },
};

const AU_MAP_VARIABLES_DESKTOP: ArchiProMapVariables = {
    center: { lat: -27.909965, lng: 135.74815 },
    zoom: {
        search: 4,
        click: 9,
        max: 19,
        min: 4,
    },
    latitudeOffset: 0.045,
    restriction: {
        latLngBounds: {
            east: 168.569469029,
            west: 98.338953078,
            north: 10.6681857235,
            south: -53.6345972634,
        },
        strictBounds: false,
    },
};

const NZ_MAP_VARIABLES_MOBILE = {
    ...NZ_MAP_VARIABLES_DESKTOP,
    latitudeOffset: 0.325,
    center: { lat: -40.2851, lng: 172.576 },
    zoom: {
        search: 5,
        click: 9,
        max: 19,
        min: 5,
    },
};

const AU_MAP_VARIABLES_MOBILE = {
    ...AU_MAP_VARIABLES_DESKTOP,
    latitudeOffset: 0.325,
    zoom: {
        search: 3.4,
        click: 9,
        max: 19,
        min: 3.4,
    },
};

const MAP_VARIABLES = {
    NZ_MAP_VARIABLES_MOBILE,
    NZ_MAP_VARIABLES_DESKTOP,
    AU_MAP_VARIABLES_MOBILE,
    AU_MAP_VARIABLES_DESKTOP,
    CA_MAP_VARIABLES_MOBILE: NZ_MAP_VARIABLES_MOBILE, // TODO: add CA map variables when it's ready
    CA_MAP_VARIABLES_DESKTOP: NZ_MAP_VARIABLES_DESKTOP,
};

const SUPPORTED_COUNTRIES: SupportedCountry[] = ['NZ', 'AU'];

export const getArchiProMapVariables = (
    countryCode: APCountry,
    isMobile = false
) => {
    const type = isMobile ? 'MOBILE' : 'DESKTOP';
    const currentCode =
        SUPPORTED_COUNTRIES.find((code) => code === countryCode) ??
        SUPPORTED_COUNTRIES[0]!;

    return MAP_VARIABLES[`${currentCode}_MAP_VARIABLES_${type}`];
};

export const INCLUDE_LINKED_PROFILES = true;
export const SCROLL_TO_MAP_AFTER_CLICK_LINK = false;
export const SCROLL_TO_MAP_AFTER_CLICK_MAP_BUTTON = false;

export const MOBILE_INITIAL_LOCATION_NUM = 8;
export const MOBILE_SEARCH_MAP_OFFSET = 140;
export const MOBILE_MAP_TOP_GAP = 30;
export const DESKTOP_MAP_TOP_GAP = 25;

export { GOOGLE_MAP_STYLES };
